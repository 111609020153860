@media screen and (max-width: 768px) {
  .contact {
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .contact .typeform-widget {
    margin-top: -5rem;
  }
}

.contact .map iframe {
  border: 0;
  height: 17rem;
  line-height: 0;
  width: 100%;
}

.contact .map .scrolloff {
  pointer-events: none;
}

.contact .gmap {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.contact .body h3 {
  font-weight: 500;
  line-height: 0.7;
}

.contact .body a {
  color: #4a4a4a;
  font-size: 1.8rem;
}

.contact .body h2 {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
}

@media screen and (min-width: 768px) {
  .contact .map iframe {
    height: 40rem;
  }
  .contact .body h1,
  .contact .body h2 {
    font-size: 3rem;
  }
  .contact .body h3 {
    padding-bottom: 1.8rem;
  }
  .contact .body p {
    margin-bottom: 2rem;
  }
  .contact .body hr {
    margin-bottom: 5.1rem;
    margin-top: 2.4rem;
    width: 6rem;
  }
}

@media screen and (min-width: 992px) {
  .contact .body h1,
  .contact .body h2 {
    font-size: 5rem;
    letter-spacing: 0.18rem;
  }
  .contact .body a {
    font-size: 1.6rem;
  }
  .contact .body hr {
    margin-bottom: 2.9rem;
    margin-top: 2rem;
  }
}

.contact .banner {
  background: url('/assets/img/background/img-header-contact-desktop.png')
    no-repeat center;
  background: -webkit-image-set(
      url('/assets/img/background/img-header-contact-desktop.png') 1x,
      url('/assets/img/background/img-header-contact-desktop@2x.png') 2x,
      url('/assets/img/background/img-header-contact-desktop@3x.png') 3x
    )
    no-repeat center;
  background-size: cover;
  height: 35rem;
}

.contact .banner .container {
  padding-top: 6.1rem;
}

.contact .banner h1 {
  color: #212121;
  font-family: 'Montserrat-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 3rem;
  margin-bottom: 1.4rem;
}

.contact .banner img {
  height: 8.1rem;
  margin-bottom: 3rem;
}

.callus .wrap,
.callus .wrap a {
  color: #fff;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  background-color: #ffc200;
  font-weight: 600;
  height: 7rem;
  letter-spacing: 0.1rem;
  padding-top: 0.5rem;
  z-index: 25;
}

@media screen and (min-width: 992px) {
  .contact .banner {
    height: 60rem;
  }
  .contact .banner .container {
    padding-top: 10.6rem;
  }
  .contact .banner h1 {
    font-size: 5rem;
    margin-bottom: 1.1rem;
  }
  .contact .banner img {
    height: 15.1rem;
    margin-bottom: 4.1rem;
  }
}
